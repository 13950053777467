import React, { useContext } from "react";

import css from "./Today.module.css";
import { Head } from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";
import { useNavigate } from "react-router-dom";

export const Explore = () => {
  const { mindList, moveList, nutriList } = useContext(ClinicContext);
  const navigate = useNavigate();

  return (
    <div className={css.container}>
      <Head title="User" description={"User - Mairini"} />
      <section>
        {/* <div className={css.myActivities}>
          <div className={`${css.feature} ${css.featureMind}`}>
            <i className={`material-icons ${css.sidebarIcon}`}>
              radio_button_unchecked
            </i>
            <p>MIND</p>
          </div>
          <div className={`${css.feature} ${css.featureMove}`}>
            <i className={`material-icons ${css.sidebarIcon}`}>
              change_history
            </i>
            <p>MOVE</p>
          </div>
          <div className={`${css.feature} ${css.featureNutri}`}>
            <i className={`material-icons ${css.sidebarIcon}`}>
              check_box_outline_blank
            </i>
            <p>NUTRI</p>
          </div>
        </div> */}
        {/* {mindList.map((m, i) => (
          <div className={css.card} key={i}>
            <h4>{m.title}</h4>
          </div>
        ))} */}
        <div className={css.card}>
          <h4>Quem sou eu?</h4>
          <p>Entenda sobre a sua personalidade</p>
        </div>
        <div className={css.card}>
          <h4>A importância dos valores</h4>
          <p>O que são valores</p>
          <p>Porque os valores são importantes?</p>
        </div>
        <div className={css.card}>
          <h4>Transformando valores em ações</h4>
          <p>Comece a pensar em atividades para o dia a dia.</p>
        </div>
        <div className={`${css.card}`}>
          <div className={`${css.cardHead} ${css.cardMind}`}>
            <div>
              <i className={`material-icons ${css.sidebarIcon}`}>
                radio_button_unchecked
              </i>
              <h4>Mind</h4>
            </div>
          </div>
          <div className={css.cardBody}>
            <span>
              <i className={`material-icons ${css.sidebarIcon}`}>schedule</i>
              <p>Manhã</p>
            </span>
            <h4>Ouvindo o presente</h4>
            <p>
              Treino de concentração ouvindo o ambiente enquanto observa os
              pensamentos.
            </p>
          </div>
          <button
            className="defaultButton"
            onClick={() => navigate("/listening-present")}
          >
            Iniciar
          </button>
        </div>
      </section>
    </div>
  );
};
