export const valuesQuestionare = [
  {
    value: "Family",
    sentences: [
      "Passar tempo com meu pai ou com minha mãe.",
      "Ter um filho.",
      "Participar da vida dos meus filhos.",
      "Visitar meus familiares.",
    ],
  },
  {
    value: "Love",
    sentences: [
      "Encontrar um parceiro que compartilhe dos meus interesses.",
      "Dar e receber afeto.",
      "Passar tempo de qualidade com meu parceiro.",
      "Mostrar para meu parceiro o quanto eu gosto dele.",
    ],
  },
  {
    value: "Career",
    sentences: [
      "Aprender novas coisas.",
      "Evoluir no meu trabalho.",
      "Ser produtivo.",
      "Conquistar independência financeira.",
    ],
  },
  {
    value: "Health",
    sentences: [
      "Fazer exercícios físicos regularmente.",
      "Ter uma melhor qualidade de sono.",
      "Ter uma alimentação mais saudável.",
      "Encontrar um sentido de vida.",
    ],
  },
  {
    value: "Social",
    sentences: [
      "Fazer novas amizades.",
      "Ser parte de um grupo.",
      "Ter pessoas com quem contar.",
      "Manter amizades verdadeiras.",
    ],
  },
  {
    value: "Fun",
    sentences: [
      "Ter mais tempo livre.",
      "Buscar atividades que me proporcionem prazer.",
      "Expressar minha criatividade.",
      "Viajar e conhecer lugares novos.",
    ],
  },
];

export const valuesQuestionarePt = [
  {
    value: "Família",
    sentences: [
      "Passar tempo com meu pai ou com minha mãe.",
      "Ter um filho.",
      "Participar da vida dos meus filhos.",
      "Visitar meus familiares.",
    ],
  },
  {
    value: "Amor",
    sentences: [
      "Encontrar um parceiro que compartilhe dos meus interesses.",
      "Dar e receber afeto.",
      "Passar tempo de qualidade com meu parceiro.",
      "Mostrar para meu parceiro o quanto eu gosto dele.",
    ],
  },
  {
    value: "Carreira",
    sentences: [
      "Aprender novas coisas.",
      "Evoluir no meu trabalho.",
      "Ser produtivo.",
      "Conquistar independência financeira.",
    ],
  },
  {
    value: "Saúde",
    sentences: [
      "Fazer exercícios físicos regularmente.",
      "Ter uma melhor qualidade de sono.",
      "Ter uma alimentação mais saudável.",
      "Encontrar um sentido de vida.",
    ],
  },
  {
    value: "Social",
    sentences: [
      "Fazer novas amizades.",
      "Ser parte de um grupo.",
      "Ter pessoas com quem contar.",
      "Manter amizades verdadeiras.",
    ],
  },
  {
    value: "Lazer",
    sentences: [
      "Ter mais tempo livre.",
      "Buscar atividades que me proporcionem prazer.",
      "Expressar minha criatividade.",
      "Viajar e conhecer lugares novos.",
    ],
  },
];

// export const VLQ = [
//   "Relações familiares (não contando as relações com o cônjuge/companheiro ou com os filhos).",
//   "Casamento/companheiro/relacionamento afetivo",
//   "Ser pai ou mãe (ou exercer esse papel)",
//   "Amizades/relações sociais",
//   "Trabalho/atividade laboral",
//   "Educação/aprendizagem",
//   "Lazer/Bem-estar",
//   "Espiritualidade/sentido de vida/religião",
//   "Vida em sociedade/cidadania (direitos e deveres sociais)",
//   "Autocuidado físico (alimentação, exercício/atividade física, descansar/dormir)",
// ];
