export const CBMPI = [
  "Você se emociona facilmente.",
  "Você tem muita energia.",
  "Você faz amigos com frequência.",
  "No trabalho é uma pessoa minuciosa e detalhista.",
  "Você é original, tem ideias novas.",
  "Você lida bem com estresse",
  "Você fica tenso com frequência.",
  "Você tende a ser quieta.",
  "Você considera a ideia de se promover em redes sociais uma tarefa difícil.",
  "Você pode ser desleixado para fazer as coisas.",
  "Você se interessa por áreas diferentes de conhecimento.",
  "Preocupações atrapalham seu bem-estar.",
  "Para fazer uma escolha, você prioriza os fatos em vez de seus sentimentos.",
  "Você gosta de atividades de movimento, como esportes e dança.",
  "É extrovertida e sociável.",
  "Você persevera até concluir as tarefas.",
  "Você considera discutir sobre teorias filosóficas abstratas uma perda de tempo.",
  "Você tem medo dos seus sentimentos.",
  "Você fica nervoso facilmente.",
  "Você tende a ser preguiçoso.",
  "Você é reservado.",
  "Você tende a ser desorganizada.",
  "Você tem uma imaginação fértil.",
  "Você é temperamental e instável emocionalmente.",
  "Você geralmente se orienta mais por argumentos factuais do que pelos sentimentos.",
  "Você prefere fazer atividades que gastem menos energia.",
  "Você gosta de participar de trabalho em equipe.",
  "Você gosta de rotina.",
  "Você prefere tarefas que tenham etapas concretas do que as que exigem soluções criativas.",
  "Você é emocionalmente estável, não se perturba facilmente.",
  "Você frequentemente chora em eventos emocionantes.",
  "Você prefere ambientes tranquilos e com poucos estímulos do que lugares movimentados e agitados.",
  "Você geralmente é tímida e inibida.",
  "Você normalmente deixa para fazer as coisas no último minuto possível.",
  "Você gosta de refletir, jogar com as ideias.",
  "Emoções causam problemas na sua vida.",
  "Ao tomar uma decisão, você foca no que é mais lógico do que em como as pessoas afetadas podem se sentir.",
  "Seus amigos descreveriam você como uma pessoa ativa.",
  "Você gostaria de um trabalho em que pudesse conhecer novas pessoas.",
  "Você cumpre e finaliza os planos que faz.",
  "Quando uma conversa se torna muito teórica você perde o interesse ou fica entediado.",
  "Suas lembranças dolorosas te impedem de ter uma vida plena.",
];
