import React, { useContext, useState, useEffect } from "react";

import css from "./Topbar.module.css";
import logoName from "../../assets/logoName.png";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import HeaderScrolled from "../../Utils/HeaderScrolled";

export const Topbar = () => {
  const { user, userLogout, userData } = useContext(UserContext);
  const { pathname } = useLocation();
  const [nav, setNav] = useState(false);
  const [pos, setPos] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const position = window.scrollY;
        setPos(window.scrollY);
        if (position > 0) {
          setNav(true);
        } else {
          setNav(false);
        }
      };

      window.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <nav className={nav ? `${css.nav} ${css.active}` : css.nav}>
      <a href="/" className={css.logo}>
        <img src={logoName} alt="" />
      </a>
      <input type="checkbox" id="menuBtn" className={css.menuBtn} />
      <label className={css.menuIcon} htmlFor="menuBtn">
        <span className={css.navIcon}></span>
      </label>
      <ul className={css.menu}>
        <div>
          {pathname === "/" || pathname === "/start" ? (
            <Link to={userData ? "/" : "/start"} className={css.link}>
              <li className={`${css.topbarListItem} ${css.active}`}>
                <i className={`material-icons ${css.topbarIcon} ${css.active}`}>
                  today
                </i>
                Hoje
              </li>
            </Link>
          ) : (
            <Link to={userData ? "/" : "/start"} className={css.link}>
              <li className={`${css.topbarListItem}`}>
                <i className={`material-icons ${css.topbarIcon}`}>today</i>
                Hoje
              </li>
            </Link>
          )}
          {pathname === "/profile" ? (
            <Link to="/profile" className={css.link}>
              <li className={`${css.topbarListItem} ${css.active}`}>
                <i className={`material-icons ${css.topbarIcon} ${css.active}`}>
                  person
                </i>
                {/* {userData ? userData.displayName : "Visitante"} */}
                Perfil
              </li>
            </Link>
          ) : (
            <Link to="/profile" className={css.link}>
              <li className={css.topbarListItem}>
                <i className={`material-icons ${css.topbarIcon}`}>person</i>
                {/* {userData ? userData.displayName : "Visitante"} */}
                Perfil
              </li>
            </Link>
          )}

          {pathname === "/schedule" ? (
            <Link to={"/schedule"} className={css.link}>
              <li className={`${css.topbarListItem} ${css.active}`}>
                <i className={`material-icons ${css.topbarIcon} ${css.active}`}>
                  insights
                </i>
                Engajamento
              </li>
            </Link>
          ) : (
            <Link to={"/schedule"} className={css.link}>
              <li className={css.topbarListItem}>
                <i className={`material-icons ${css.topbarIcon}`}>insights</i>
                Engajamento
              </li>
            </Link>
          )}
        </div>
        {/* {pathname === "/schedule" ? (
          <Link to={"/schedule"} className={css.link}>
            <li className={`${css.topbarListItem} ${css.active}`}>
              <i className={`material-icons ${css.topbarIcon} ${css.active}`}>
                calendar_view_month
              </i>
              Agenda
            </li>
          </Link>
        ) : (
          <Link to={"/schedule"} className={css.link}>
            <li className={css.topbarListItem}>
              <i className={`material-icons ${css.topbarIcon}`}>
                calendar_view_month
              </i>
              Agenda
            </li>
          </Link>
        )} */}
        {/* {pathname === "/explore" ? (
          <Link to={"/explore"} className={css.link}>
            <li className={`${css.topbarListItem} ${css.active}`}>
              <i className={`material-icons ${css.topbarIcon} ${css.active}`}>
                explore
              </i>
              Explorar
            </li>
          </Link>
        ) : (
          <Link to={"/explore"} className={css.link}>
            <li className={`${css.topbarListItem}`}>
              <i className={`material-icons ${css.topbarIcon}`}>explore</i>
              Explorar
            </li>
          </Link>
        )} */}
        <div>
          {
            <Link to="/contact" className={css.link}>
              <li
                className={
                  pathname === "/contact"
                    ? `${css.topbarListItem} ${css.active}`
                    : css.topbarListItem
                }
              >
                <i
                  className={
                    pathname === "/contact"
                      ? `material-icons ${css.topbarIcon} ${css.active}`
                      : `material-icons ${css.topbarIcon}`
                  }
                >
                  call
                </i>
                Contato
              </li>
            </Link>
          }

          {userData ? (
            <Link to="/account" className={css.link}>
              <li
                className={
                  pathname === "/account"
                    ? `${css.topbarListItem} ${css.active}`
                    : css.topbarListItem
                }
              >
                <i
                  className={
                    pathname === "/account"
                      ? `material-icons ${css.topbarIcon} ${css.active}`
                      : `material-icons ${css.topbarIcon}`
                  }
                >
                  settings
                </i>
                Conta
              </li>
            </Link>
          ) : (
            <li className={`${css.topbarListItem} ${css.disabled}`}>
              <i className={`material-icons ${css.topbarIcon} ${css.disabled}`}>
                settings
              </i>
              Conta
            </li>
          )}

          {userData ? (
            <Link to="/logout" className={css.link}>
              <li className={css.topbarListItem}>
                <i className={`material-icons ${css.topbarIcon}`}>logout</i>
                Sair
              </li>
            </Link>
          ) : (
            <Link to="/login" className={css.link}>
              <li className={css.topbarListItem}>
                <i className={`material-icons ${css.topbarIcon}`}>login</i>
                Entrar
              </li>
            </Link>
          )}
        </div>
      </ul>
    </nav>
  );
};
