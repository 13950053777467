import React, { useState, useContext, useEffect, useReducer } from "react";
import dayjs from "dayjs";

import { UserContext } from "./UserContext";
import {
  getActFirebase,
  getAppointmentsPatFirebase,
  getPostListsFirebase,
} from "../Utils/firebaseClinic";
import { savedAppointmentsDocDayReducer } from "./clinicReducer";
import { getProfessionalsFirebase } from "../Utils/firebaseUser";

export const ClinicContext = React.createContext();

export const ClinicStorage = ({ children }) => {
  const { userData } = useContext(UserContext);
  const [diary, setDiary] = useState({});
  const [mindList, setMindList] = useState([]);
  const [moveList, setMoveList] = useState([]);
  const [nutriList, setNutriList] = useState([]);
  const [appointmentsDocDay, dispatchAppointmentsDocDay] = useReducer(
    savedAppointmentsDocDayReducer,
    []
  );
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(dayjs().month());
  const [professionals, setProfessionals] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [timeSelected, setTimeSelected] = useState("");
  const [docSelected, setDocSelected] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [view, setView] = useState("day");
  const [loadingClinic, setLoadingClinic] = useState(null);

  useEffect(() => {
    if (userData) {
      getPostListsFirebase().then(function (result) {
        if (result) {
          if (result.mindList[0] && !mindList[0]) {
            setMindList(result.mindList);
          }
          if (result.moveList[0] && !moveList[0]) {
            setMoveList(result.moveList);
          }
          if (result.nutriList[0] && !nutriList[0]) {
            setNutriList(result.nutriList);
          }
        }
      });
    } else {
      console.log("no postList");
    }
  }, [userData, mindList, moveList, nutriList]);

  // useEffect(() => {
  //   if (userData) {
  //     getAppointmentsPatFirebase(userData.email).then(function (result) {
  //       if (result[0]) {
  //         let array = result.sort((a, b) =>
  //           a.dtStart < b.dtStart ? 1 : b.dtStart < a.dtStart ? -1 : 0
  //         );
  //         setAppointments(array);
  //       }
  //     });
  //   }
  //   getProfessionalsFirebase().then(function (result) {
  //     if (result[0]) {
  //       setProfessionals(result);
  //     } else {
  //       console.log("no professionals");
  //     }
  //   });
  // }, [userData]);

  return (
    <ClinicContext.Provider
      value={{
        diary,
        setDiary,
        mindList,
        setMindList,
        moveList,
        setMoveList,
        nutriList,
        setNutriList,
        appointmentsDocDay,
        dispatchAppointmentsDocDay,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        docSelected,
        setDocSelected,
        showAppointmentModal,
        setShowAppointmentModal,
        selectedAppointment,
        setSelectedAppointment,
        view,
        setView,
        loadingClinic,
        setLoadingClinic,
        timeSelected,
        setTimeSelected,
        appointments,
        setAppointments,
        professionals,
        setProfessionals,
      }}
    >
      {children}
    </ClinicContext.Provider>
  );
};
