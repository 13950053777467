import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Navigate, useNavigate } from "react-router-dom";

import css from "./Today.module.css";
import { UserContext } from "../../Context/UserContext";
import { ClinicContext } from "../../Context/ClinicContext";
import { Head } from "../../Utils/Head";
import { Input } from "../Forms/Input";
import { useForm } from "../../Hooks/useForm";
import { updateUserFirebase } from "../../Utils/firebaseUser";
import {
  valuesQuestionare,
  valuesQuestionarePt,
} from "../../Utils/valuesQuestionare";
import { newId } from "../../Utils/newId";

export const Today = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [newActModal, setNewActModal] = useState(false);
  const [exActModal, setExActModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [errorDone, setErrorDone] = useState("");
  const [actsToday, setActsToday] = useState([]);
  const [actsTomorrow, setActsTomorrow] = useState([]);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [value5, setValue5] = useState("");
  const [value6, setValue6] = useState("");
  const [selectedValue, setSelectedValue] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().add(1, "day").format("YYMMDD")
  );
  const [newAct, setNewAct] = useState("");
  const [mood, setMood] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    userData &&
      userData.diary &&
      userData.diary[dayjs().add(1, "day").format("YYMMDD")] &&
      setActsTomorrow([
        ...userData.diary[dayjs().add(1, "day").format("YYMMDD")].filter(
          (a) => !a.done
        ),
      ]);
    userData &&
      userData.diary &&
      userData.diary[dayjs().format("YYMMDD")] &&
      setActsToday([
        ...userData.diary[dayjs().format("YYMMDD")].filter((a) => !a.done),
      ]);

    const valuesScores = userData ? [...userData.listValues] : [];

    const valuesNames = [
      {
        name: valuesQuestionarePt[0].value,
        score: valuesScores[0],
      },
      { name: valuesQuestionarePt[1].value, score: valuesScores[1] },
      { name: valuesQuestionarePt[2].value, score: valuesScores[2] },
      { name: valuesQuestionarePt[3].value, score: valuesScores[3] },
      { name: valuesQuestionarePt[4].value, score: valuesScores[4] },
      { name: valuesQuestionarePt[5].value, score: valuesScores[5] },
    ];

    valuesNames.sort((a, b) => b.score - a.score);

    setSelectedValue(valuesNames[0].name);
    setValue1(valuesNames[0].name);
    setValue2(valuesNames[1].name);
    setValue3(valuesNames[2].name);
    setValue4(valuesNames[3].name);
    setValue5(valuesNames[4].name);
    setValue6(valuesNames[5].name);
  }, [userData]);

  function handleValidationAct() {
    let formIsValid = true;
    if (!newAct) {
      formIsValid = false;
      setErrorAlert("É preciso descrever uma Ação");
    } else {
      setErrorAlert("");
    }
    return formIsValid;
  }

  function createAct() {
    const listTomorrow = [...actsTomorrow];
    const listToday = [...actsToday];
    const obj = {
      id: newId(),
      value: selectedValue,
      act: newAct,
      mood: "",
      done: false,
      date: selectedDate,
    };
    selectedDate === dayjs().add(1, "day").format("YYMMDD") &&
      listTomorrow.push(obj);
    selectedDate === dayjs().format("YYMMDD") && listToday.push(obj);

    if (!userData.diary) {
      userData.diary = {};
    } else if (userData.diary[selectedDate]) {
      userData.diary[selectedDate].push(obj);
    } else {
      selectedDate === dayjs().add(1, "day").format("YYMMDD")
        ? (userData.diary[selectedDate] = listTomorrow)
        : (userData.diary[selectedDate] = listToday);
    }

    setUserData(userData);
    setActsTomorrow(listTomorrow);
    setActsToday(listToday);
    setNewAct("");
    setErrorAlert("");
    setSelectedDate(dayjs().add(1, "day").format("YYMMDD"));
    setSelectedValue(value1);
    updateUserFirebase(userData);
    setNewActModal(false);
    // errorAlert("Ação criada");
  }

  function handleDeleteAct(a) {
    let updatedList = [...userData.diary[a.date]];
    updatedList = updatedList.filter((e) => e.id !== a.id);
    userData.diary[a.date] = [...updatedList];

    a.date === dayjs().format("YYMMDD") && setActsToday(updatedList);
    a.date === dayjs().add(1, "day").format("YYMMDD") &&
      setActsTomorrow(updatedList);

    setUserData(userData);
    updateUserFirebase(userData);
  }

  function handleValidationDone(a) {
    let formIsValid = true;
    if (!mood) {
      formIsValid = false;
      setErrorDone(a.id);
    } else if (mood && mood.slice(1) !== a.id) {
      formIsValid = false;
      setErrorDone(a.id);
    } else {
      setErrorAlert("");
    }
    return formIsValid;
  }

  function handleActDone(a) {
    let updatedList = [...userData.diary[a.date]];

    updatedList.forEach((element) => {
      if (element.id === a.id) {
        element.mood = mood[0];
        element.done = true;
      }
    });
    userData.diary[a.date] = [...updatedList];

    a.date === dayjs().format("YYMMDD") &&
      setActsToday(updatedList.filter((a) => !a.done));
    a.date === dayjs().add(1, "day").format("YYMMDD") &&
      setActsTomorrow(updatedList.filter((a) => !a.done));

    setUserData(userData);
    updateUserFirebase(userData);
  }

  if (userData && !userData.personalityTraits[0]) {
    return <Navigate to="/start" />;
  } else if (
    userData &&
    userData.personalityTraits[0] &&
    !userData.listValues[0]
  ) {
    return <Navigate to="/start" />;
  } else if (
    userData &&
    userData.personalityTraits[0] &&
    userData.listValues[0]
  ) {
    return (
      <div className={css.container}>
        <Head title="Today" description={"User - Mairini"} />
        <section>
          <div className={css.topSection}>
            <button
              className="orangeButton"
              onClick={() => setNewActModal(true)}
            >
              Nova Ação
            </button>
            {/* <button className="blueButton" onClick={() => setExActModal(true)}>
              Exemplos Personalizados
            </button> */}
          </div>

          {userData.diary && (
            <>
              {actsToday[0] &&
                actsToday.map((a) => (
                  <div className={css.card} key={a.id}>
                    <div className={css.cardHead}>
                      <h4>{a.value}</h4>
                      <i
                        className={`material-icons materialIcon`}
                        onClick={() => setShowDeleteModal(a)}
                      >
                        delete
                      </i>
                    </div>
                    <h1>{a.act}</h1>
                    {a.date === dayjs().add(1, "day").format("YYMMDD") && (
                      <h1>Hoje</h1>
                    )}
                    {a.date === dayjs().format("YYMMDD") && <h1>Hoje</h1>}
                    <div className={css.cardMood}>
                      <p>Como foi a atividade?</p>
                      {mood === 0 + a.id ? (
                        <i
                          className={`material-icons materialIcons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          mood_bad
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(0 + a.id)}
                        >
                          mood_bad
                        </i>
                      )}

                      {mood === 1 + a.id ? (
                        <i
                          className={`material-icons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          sentiment_very_dissatisfied
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(1 + a.id)}
                        >
                          sentiment_very_dissatisfied
                        </i>
                      )}
                      {mood === 2 + a.id ? (
                        <i
                          className={`material-icons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          sentiment_satisfied
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(2 + a.id)}
                        >
                          sentiment_satisfied
                        </i>
                      )}
                      {mood === 3 + a.id ? (
                        <i
                          className={`material-icons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          sentiment_satisfied_alt
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(3 + a.id)}
                        >
                          sentiment_satisfied_alt
                        </i>
                      )}
                      {mood === 4 + a.id ? (
                        <i
                          className={`material-icons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          sentiment_very_satisfied
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(4 + a.id)}
                        >
                          sentiment_very_satisfied
                        </i>
                      )}
                    </div>
                    {errorDone === a.id && (
                      <p className={css.error}>
                        É preciso avaliar a atividade antes de concluí-la
                      </p>
                    )}
                    <button
                      className="greenButton"
                      onClick={() =>
                        handleValidationDone(a) && handleActDone(a)
                      }
                    >
                      Concluída
                    </button>
                  </div>
                ))}
              {actsTomorrow[0] &&
                actsTomorrow.map((a, i) => (
                  <div className={css.card} key={a.id}>
                    <div className={css.cardHead}>
                      <h4>{a.value}</h4>
                      <i
                        className={`material-icons materialIcon`}
                        onClick={() => setShowDeleteModal(a)}
                      >
                        delete
                      </i>
                    </div>
                    <h1>{a.act}</h1>
                    {a.date === dayjs().add(1, "day").format("YYMMDD") && (
                      <h1>Amanhã</h1>
                    )}
                    {a.date === dayjs().format("YYMMDD") && <h1>Hoje</h1>}
                    <div className={css.cardMood}>
                      <p>Como foi a atividade?</p>
                      {mood === 0 + a.id ? (
                        <i
                          className={`material-icons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          mood_bad
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(0 + a.id)}
                        >
                          mood_bad
                        </i>
                      )}

                      {mood === 1 + a.id ? (
                        <i
                          className={`material-icons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          sentiment_very_dissatisfied
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(1 + a.id)}
                        >
                          sentiment_very_dissatisfied
                        </i>
                      )}
                      {mood === 2 + a.id ? (
                        <i
                          className={`material-icons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          sentiment_satisfied
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(2 + a.id)}
                        >
                          sentiment_satisfied
                        </i>
                      )}
                      {mood === 3 + a.id ? (
                        <i
                          className={`material-icons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          sentiment_satisfied_alt
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(3 + a.id)}
                        >
                          sentiment_satisfied_alt
                        </i>
                      )}
                      {mood === 4 + a.id ? (
                        <i
                          className={`material-icons ${css.cardMoodActive}`}
                          onClick={() => setMood(false)}
                        >
                          sentiment_very_satisfied
                        </i>
                      ) : (
                        <i
                          className={`material-icons ${css.cardUserIcon}`}
                          onClick={() => setMood(4 + a.id)}
                        >
                          sentiment_very_satisfied
                        </i>
                      )}
                    </div>
                    {errorDone === a.id && (
                      <p className={css.error}>
                        É preciso avaliar a atividade antes de concluí-la
                      </p>
                    )}
                    <button
                      className="greenButton"
                      onClick={() =>
                        handleValidationDone(a) && handleActDone(a)
                      }
                    >
                      Concluída
                    </button>
                  </div>
                ))}
            </>
          )}
        </section>

        {newActModal && (
          <div className={css.modalContainer}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => {
                    setNewActModal(false);
                    setNewAct("");
                    setErrorAlert("");
                    setSelectedDate(dayjs().add(1, "day").format("YYMMDD"));
                    setSelectedValue(value1);
                  }}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <div>
                  <h4>Escolha uma direção</h4>
                  <div className={css.myValues}>
                    <label className={css.confirmation}>
                      {value1}
                      <input
                        type="checkbox"
                        checked={selectedValue === value1 ? true : false}
                        onChange={() => {
                          setSelectedValue(value1);
                        }}
                      />
                      <span className={css.checkmark}></span>
                    </label>
                    <label className={css.confirmation}>
                      {value2}
                      <input
                        type="checkbox"
                        checked={selectedValue === value2 ? true : false}
                        onChange={() => {
                          setSelectedValue(value2);
                        }}
                      />
                      <span className={css.checkmark}></span>
                    </label>
                    <label className={css.confirmation}>
                      {value3}
                      <input
                        type="checkbox"
                        checked={selectedValue === value3 ? true : false}
                        onChange={() => {
                          setSelectedValue(value3);
                        }}
                      />
                      <span className={css.checkmark}></span>
                    </label>
                  </div>
                  <div className={css.myValues}>
                    <label className={css.confirmation}>
                      {value4}
                      <input
                        type="checkbox"
                        checked={selectedValue === value4 ? true : false}
                        onChange={() => {
                          setSelectedValue(value4);
                        }}
                      />
                      <span className={css.checkmark}></span>
                    </label>
                    <label className={css.confirmation}>
                      {value5}
                      <input
                        type="checkbox"
                        checked={selectedValue === value5 ? true : false}
                        onChange={() => {
                          setSelectedValue(value5);
                        }}
                      />
                      <span className={css.checkmark}></span>
                    </label>
                    <label className={css.confirmation}>
                      {value6}
                      <input
                        type="checkbox"
                        checked={selectedValue === value6 ? true : false}
                        onChange={() => {
                          setSelectedValue(value6);
                        }}
                      />
                      <span className={css.checkmark}></span>
                    </label>
                  </div>

                  <hr />

                  <h4>Transforme em ação</h4>
                  <Input
                    type="text"
                    name="newAct"
                    placeholder={"Descreva a ação a ser feita..."}
                    value={newAct}
                    onChange={(e) => setNewAct(e.target.value)}
                    autoComplete="off"
                  />

                  <hr />

                  <h4>Escolha uma data</h4>
                  <div className={css.myValues}>
                    <label className={css.confirmation}>
                      Hoje ({dayjs().format("DD/MM/YY")})
                      <input
                        type="checkbox"
                        checked={
                          selectedDate === dayjs().format("YYMMDD")
                            ? true
                            : false
                        }
                        onChange={() => {
                          setSelectedDate(dayjs().format("YYMMDD"));
                        }}
                      />
                      <span className={css.checkmark}></span>
                    </label>
                    <label className={css.confirmation}>
                      Amanhã ({dayjs().add(1, "day").format("DD/MM/YY")})
                      <input
                        type="checkbox"
                        checked={
                          selectedDate ===
                          dayjs().add(1, "day").format("YYMMDD")
                            ? true
                            : false
                        }
                        onChange={() => {
                          setSelectedDate(
                            dayjs().add(1, "day").format("YYMMDD")
                          );
                        }}
                      />
                      <span className={css.checkmark}></span>
                    </label>
                  </div>
                </div>

                <div className={css.error}>
                  {errorAlert && <p className={css.error}>{errorAlert}</p>}
                </div>

                <footer className={css.modalFooter}>
                  <button
                    className="defaultButton"
                    onClick={(e) => {
                      setNewActModal(false);
                      setNewAct("");
                      setErrorAlert("");
                      setSelectedDate(dayjs().add(1, "day").format("YYMMDD"));
                      setSelectedValue(value1);
                    }}
                  >
                    Fechar
                  </button>
                  <button
                    className="greenButton"
                    onClick={() => {
                      handleValidationAct() && createAct();
                    }}
                  >
                    Criar ação
                  </button>
                </footer>
              </div>
            </div>
          </div>
        )}

        {exActModal && (
          <div className={css.modalContainer}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setExActModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h4>...</h4>

                <footer className={css.modalFooter}>
                  <button
                    className="defaultButton"
                    onClick={(e) => {
                      setExActModal(false);
                    }}
                  >
                    Fechar
                  </button>
                </footer>
              </div>
            </div>
          </div>
        )}

        {showDeleteModal && (
          <div className={css.modalContainer}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setShowDeleteModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h4>Deletar ação: {showDeleteModal.act}</h4>

                <footer className={css.modalFooter}>
                  <button
                    className="defaultButton"
                    onClick={() => {
                      setShowDeleteModal(false);
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      handleDeleteAct(showDeleteModal);
                      setShowDeleteModal(false);
                    }}
                  >
                    Deletar
                  </button>
                </footer>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={css.container}>
        <section>
          <div className={css.schedule}>
            <h2>Agenda {dayjs().format("DD.MM.YY")}</h2>
            <h5>Aqui você visualiza a agenda do seu dia.</h5>
          </div>
        </section>
      </div>
    );
  }
};
