import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import css from "./Schedule.module.css";
import { UserContext } from "../../Context/UserContext";
import { getWeek } from "../../Utils/getDate";
import { Head } from "../../Utils/Head";
import { Input } from "../Forms/Input";
import {
  createScheduleFirebase,
  getPracticesFirebase,
} from "../../Utils/firebaseClinic";
import { updateUserFirebase } from "../../Utils/firebaseUser";
import { valuesQuestionarePt } from "../../Utils/valuesQuestionare";

export const Schedule = () => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const [weekActs, setWeekActs] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const currentWeek = getWeek();
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [value5, setValue5] = useState("");
  const [value6, setValue6] = useState("");
  const [listValue, setListValue] = useState(new Array(7).fill(""));
  const [listValue2, setListValue2] = useState(new Array(7).fill(""));
  const [listValue3, setListValue3] = useState(new Array(7).fill(""));
  const [listValue4, setListValue4] = useState(new Array(7).fill(""));
  const [listValue5, setListValue5] = useState(new Array(7).fill(""));
  const [listValue6, setListValue6] = useState(new Array(7).fill(""));

  useEffect(() => {
    let arr = [];

    if (userData) {
      currentWeek.forEach((d, i) => {
        if (userData.diary[dayjs(d).format("YYMMDD")]) {
          arr = [...arr, ...userData.diary[dayjs(d).format("YYMMDD")]];
          listValue[i] = userData.diary[dayjs(d).format("YYMMDD")];
          setListValue(listValue);
        }
      });
    }

    setWeekActs(arr);
    setCompletedList(arr.filter((a) => a.done));

    const valuesScores = userData ? [...userData.listValues] : [];

    const valuesNames = [
      {
        name: valuesQuestionarePt[0].value,
        score: valuesScores[0],
      },
      { name: valuesQuestionarePt[1].value, score: valuesScores[1] },
      { name: valuesQuestionarePt[2].value, score: valuesScores[2] },
      { name: valuesQuestionarePt[3].value, score: valuesScores[3] },
      { name: valuesQuestionarePt[4].value, score: valuesScores[4] },
      { name: valuesQuestionarePt[5].value, score: valuesScores[5] },
    ];

    valuesNames.sort((a, b) => b.score - a.score);

    setValue1(valuesNames[0].name);
    setValue2(valuesNames[1].name);
    setValue3(valuesNames[2].name);
    setValue4(valuesNames[3].name);
    setValue5(valuesNames[4].name);
    setValue6(valuesNames[5].name);
  }, [userData]);

  function getCurrentDayClass(day) {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? css.currentDay
      : "";
  }

  function handleTimeByDay(day) {
    const dayPor = dayjs(day).format("ddd");
    switch (dayPor) {
      case "Sun":
        return "Dom";
      case "Mon":
        return "Seg";
      case "Tue":
        return "Ter";
      case "Wed":
        return "Qua";
      case "Thu":
        return "Qui";
      case "Fri":
        return "Sex";
      case "Sat":
        return "Sáb";
      default:
        return;
    }
  }

  function handleMood(m) {
    switch (m) {
      case "0":
        return <i className={`material-icons ${css.moodIcon}`}>mood_bad</i>;
      case "1":
        return (
          <i className={`material-icons ${css.moodIcon}`}>
            sentiment_very_dissatisfied
          </i>
        );
      case "2":
        return (
          <i className={`material-icons ${css.moodIcon}`}>
            sentiment_satisfied
          </i>
        );
      case "3":
        return (
          <i className={`material-icons ${css.moodIcon}`}>
            sentiment_satisfied_alt
          </i>
        );
      case "4":
        return (
          <i className={`material-icons ${css.moodIcon}`}>
            sentiment_very_satisfied
          </i>
        );
      default:
        return;
    }
  }

  return (
    // <div className="containerContent">
    <div className={css.container}>
      <Head title="Schedule" description="Schedule - Mairini" />
      <p>Ações dessa semana: {weekActs.length}</p>
      <p>Ações concluídas essa semana: {completedList.length}</p>
      <div className={css.month}>
        {currentWeek.map((day, i) => (
          <span className={css.cell_title} key={i}>
            {handleTimeByDay(day)}{" "}
            <span className={`${css.dayNumber} ${getCurrentDayClass(day)}`}>
              {dayjs(day).format("DD")}
            </span>
          </span>
        ))}

        {listValue.map((act, idx) => (
          <span className={`${css.day_name}`} key={idx}>
            {act[0] &&
              act.map((a, i) =>
                a.mood ? (
                  <div key={i} className={css.eventActDone}>
                    <p>{a.act}</p>
                    <p>
                      {a.value} {handleMood(a.mood)}
                    </p>
                  </div>
                ) : (
                  <div key={i} className={css.eventAct}>
                    <p>{a.act}</p>
                    <p>{a.value}</p>
                  </div>
                )
              )}
          </span>
        ))}
      </div>
    </div>
  );
};
