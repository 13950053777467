import React, { useContext, useState } from "react";

import css from "./ProfilePage.module.css";
import { useNavigate } from "react-router-dom";

import { valuesQuestionarePt } from "../../Utils/valuesQuestionare";
import { UserContext } from "../../Context/UserContext";
import { updateUserFirebase } from "../../Utils/firebaseUser";
import { Button } from "../Forms/Button";

export const Values = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const VLQarr = new Array(6);
  for (let i = 0; i < VLQarr.length; i++) {
    VLQarr[i] = new Array(6).fill(false);
  }
  const [VLQCheck, setVLQCheck] = useState(VLQarr);
  const [completeVLQ, setCompleteVLQ] = useState(0);
  const [points, setPoints] = useState(18);
  const navigate = useNavigate();

  function handleCheckVLQ(row, col) {
    let sum = completeVLQ + 1;
    setCompleteVLQ(sum);
    let array = [...VLQCheck];
    if (array[row].includes(true)) {
      if (array[row][col]) {
        array[row][col] = false;
        setPoints(points + col);
        setVLQCheck(array);
      } else {
        let position = array[row].indexOf(true);
        if (points - col + position < 0) {
          alert("número máximo de pontos atingido");
        } else {
          array[row][position] = false;
          array[row][col] = !array[row][col];
          setPoints(points - col + position);
          setVLQCheck(array);
        }
      }
    } else {
      if (points - col < 0) {
        alert("número máximo de pontos atingido");
      } else {
        array[row][col] = !array[row][col];
        setPoints(points - col);
        setVLQCheck(array);
      }
    }
  }

  function submmit() {
    let VLQReduced = VLQCheck.map((row) => row.indexOf(true));
    if (VLQReduced.indexOf(-1) >= 0) {
      alert("Avalie todas as categorias");
    } else {
      const VLQAnswers = new Array(6).fill("");
      for (let index = 0; index < VLQReduced.length; index++) {
        VLQAnswers[index] = valuesQuestionarePt[VLQReduced[index]]
          ? valuesQuestionarePt[VLQReduced[index]]
          : "";
      }

      userData.listValues = VLQReduced;

      updateUserFirebase(userData);
      setUserData(userData);
      navigate("/profile");
    }
  }

  if (index === 0) {
    return (
      <div className={css.container}>
        <h3>Valores de Vida</h3>
        <p>
          Nesse questionário estão listadas áreas da vida que são valorizadas
          por algumas pessoas. Avalie a importância que você dá a cada uma
          dessas áreas para a sua vida, independentemente de sua situação atual.
        </p>
        <p>
          Nem todas as pessoas irão valorizá-las ou avaliá-las da mesma forma.
          Utilize as sentenças como exemplos de cada categoria.
        </p>
        <p>
          Você terá 18 pontos para distribuir entre as 6 categorias, conforme a
          importância.
        </p>
        <p>Clique abaixo para iniciar</p>
        <div className={css.footer}>
          <button className="saveButton" onClick={() => setIndex(1)}>
            Iniciar
          </button>
        </div>
      </div>
    );
  } else if (index === 1) {
    return (
      <div className={css.container}>
        <form>
          <p>Distribua até {points} pontos entre as categorias abaixo</p>
          {valuesQuestionarePt.map((question, row) => (
            <React.Fragment key={row}>
              <h4>{question.value}</h4>

              {question.sentences.map((s, i) => (
                <ul key={i}>
                  <li>{s}</li>
                </ul>
              ))}

              <div className={css.traitName}>
                <label className={css.confirmation}>
                  (0) Não importante
                  <input
                    type="checkbox"
                    checked={VLQCheck[row][0]}
                    onChange={() => {
                      handleCheckVLQ(row, 0);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  (1) Pouco importante
                  <input
                    type="checkbox"
                    checked={VLQCheck[row][1]}
                    onChange={() => {
                      handleCheckVLQ(row, 1);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  (2) Importante
                  <input
                    type="checkbox"
                    checked={VLQCheck[row][2]}
                    onChange={() => {
                      handleCheckVLQ(row, 2);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  (3) Muito importante
                  <input
                    type="checkbox"
                    checked={VLQCheck[row][3]}
                    onChange={() => {
                      handleCheckVLQ(row, 3);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  (4) Extremamente importante
                  <input
                    type="checkbox"
                    checked={VLQCheck[row][4]}
                    onChange={() => {
                      handleCheckVLQ(row, 4);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
              </div>
            </React.Fragment>
          ))}
        </form>
        <div className={css.footer}>
          <Button
            onClick={() => {
              submmit();
            }}
          >
            Enviar
          </Button>
        </div>
      </div>
    );
  }
};
