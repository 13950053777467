import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import "./theme.css";
import { UserStorage } from "./Context/UserContext";
import { ClinicStorage } from "./Context/ClinicContext";
import { Account } from "./Components/Account/Account";
import { RequireAuth } from "./Utils/RequireAuth";
import { Content } from "./Components/Layout/Content";
import { Login } from "./Components/Login/Login";
import { Logout } from "./Components/Login/Logout";
import { LoginCreate } from "./Components/Login/LoginCreate";
import { Submission } from "./Components/Login/Submission";
import { Today } from "./Components/Home/Today";
import { HomePage } from "./Components/Home/HomePage";
import { Contact } from "./Components/Home/Contact";
import { Appointment } from "./Components/Appointment/Appointment";
import { Professionals } from "./Components/Home/Professionals";
import { ProPage } from "./Components/Home/ProPage";
import { Request } from "./Components/Home/Request";
import { MyMind } from "./Components/Mind/MyMind";
import { MyMove } from "./Components/Move/MyMove";
import { MyNutri } from "./Components/Nutri/MyNutri";
import { NewPost } from "./Components/Post/NewPost";
import { EditPost } from "./Components/Post/EditPost";
import { Theory } from "./Components/Pillar/Theory";
import { Practice } from "./Components/Pillar/Practice";
import { Type } from "./Components/Pillar/Type";
import { Schedule } from "./Components/Schedule/Schedule";
import { Mind } from "./Components/Mind/Mind";
import { Move } from "./Components/Move/Move";
import { Nutri } from "./Components/Nutri/Nutri";
import { ProfilePage } from "./Components/Profile/ProfilePage";
import { Favorites } from "./Components/Home/Favorites";
import { ProfileData } from "./Components/Profile/ProfileData";
import { ListProblems } from "./Components/Profile/ListProblems";
import { ListGoals } from "./Components/Profile/ListGoals";
import { ActionPlan } from "./Components/Profile/ActionPlan";
import { Values } from "./Components/Profile/Values";
import { WordCloud } from "./Components/Know/WordCloud";
import { Cloud } from "./Components/Cloud/Cloud";
import { TerAct } from "./Components/TerAct/TerAct";
import { Know } from "./Components/Know/Know";
import { Explore } from "./Components/Home/Explore";
import { About } from "./Components/Home/About";
import { Audios } from "./Components/Home/Audios";
import { Articles } from "./Components/Home/Articles";
import { ListeningPresent } from "./Components/TerAct/ListeningPresent";
import { ListeningNature } from "./Components/TerAct/ListeningNature";
import { Post } from "./Components/Post/Post";
import { Topbar } from "./Components/Layout/Topbar";
import { Personality } from "./Components/Home/Personality";
import { Start } from "./Components/Home/Start";

function App() {
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const toggleMenu = (e) => {
    setIsMenuVisible(!isMenuVisible);
  };

  const TopbarLayout = () => (
    <>
      {/* <Header onMenuClick={toggleMenu} /> */}
      <Topbar isMenuVisible={isMenuVisible} />
      <Content
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
    </>
  );
  return (
    <UserStorage>
      <ClinicStorage>
        <Routes>
          <Route path="login/*" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          {/* <Route path="/signup" element={<Submission />} /> */}
          <Route path="/home" element={<HomePage />} />
          <Route path="/audios" element={<Audios />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/about" element={<About />} />
          <Route path="/request" element={<Request />} />
          <Route path="/register" element={<LoginCreate />} />
          <Route element={<TopbarLayout />}>
            <Route path="/start" element={<Start />} />
            <Route path="/personality" element={<Personality />} />
            <Route path="/post/:slug" element={<Post />} />
            <Route path="/listening-present" element={<ListeningPresent />} />
            <Route path="/listening-nature" element={<ListeningNature />} />
            {/* <Route path="/start" element={<Today />} /> */}
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/know" element={<Know />} />
            <Route path="/wordcloud" element={<WordCloud />} />
            <Route path="/cloud" element={<Cloud />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/mind" element={<Mind />} />
            <Route path="/move" element={<Move />} />
            <Route path="/nutri" element={<Nutri />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route element={<TopbarLayout />}>
              <Route path="/" element={<Today />} />
              <Route path="/teract/*" element={<TerAct />} />
              <Route path="/data" element={<ProfileData />} />
              <Route path="/listproblems" element={<ListProblems />} />
              <Route path="/listgoals" element={<ListGoals />} />
              <Route path="/actionplan" element={<ActionPlan />} />
              <Route path="/values" element={<Values />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/account/*" element={<Account />} />
              <Route path="/mairini/:pillar" element={<Theory />} />
              <Route path="/mairini/:pillar/practice" element={<Type />} />
              <Route
                path="/mairini/:pillar/practice/:type"
                element={<Practice />}
              />
              <Route path="/newpost" element={<NewPost />} />
              <Route path="/editpost" element={<EditPost />} />
              <Route path="/mymind" element={<MyMind />} />
              <Route path="/mymove" element={<MyMove />} />
              <Route path="/mynutri" element={<MyNutri />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/appointment" element={<Appointment />} />
              <Route path="/pros" element={<Professionals />} />
              <Route path="/pro/:id" element={<ProPage />} />
            </Route>
          </Route>
        </Routes>
      </ClinicStorage>
    </UserStorage>
  );
}

export default App;
