import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import css from "./ProfilePage.module.css";
import { UserContext } from "../../Context/UserContext";
import { Head } from "../../Utils/Head";
import { valuesQuestionarePt } from "../../Utils/valuesQuestionare";

export const ProfilePage = () => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  function handleCharacter(trait, p) {
    let character;
    let percentage;
    if (trait === "regras" && p >= 50) {
      character = "Disciplinado";
      percentage = p;
    } else if (trait === "regras" && p < 50) {
      character = "Espontâneo";
      percentage = p - 100;
    } else if (trait === "abstração" && p >= 50) {
      character = "Imaginativo";
      percentage = p;
    } else if (trait === "abstração" && p < 50) {
      character = "Realista";
      percentage = p - 100;
    } else if (trait === "flexibilidade" && p >= 50) {
      character = "Assertivo";
      percentage = p;
    } else if (trait === "flexibilidade" && p < 50) {
      character = "Turbulento";
      percentage = p - 100;
    }
    return `${Math.abs(percentage)}% ${character}`;
  }

  return (
    <>
      <Head title="Profile" description={"Profile - Mairini"} />
      {userData ? (
        <div className={css.profile}>
          <div className={css.profile5}>
            <div className={css.valuesHead}>
              <div>
                <h4>Direções de vida</h4>
              </div>
              <div>
                <i
                  className={`material-icons ${css.cardIcon}`}
                  onClick={() => navigate("/values")}
                >
                  edit_note
                </i>
              </div>
            </div>
            <div className={css.valuesBody}>
              {userData.listValues && (
                <div>
                  <p>{valuesQuestionarePt[0].value}</p>
                  <div className={css.valuesBar}>
                    <div
                      className={css.valuesScore}
                      style={{
                        marginRight: `${
                          ((5 - userData.listValues[0]) * 100) / 5
                        }%`,
                      }}
                    ></div>
                  </div>
                  <p>{valuesQuestionarePt[1].value}</p>
                  <div className={css.valuesBar}>
                    <div
                      className={css.valuesScore}
                      style={{
                        marginRight: `${
                          ((5 - userData.listValues[1]) * 100) / 5
                        }%`,
                      }}
                    ></div>
                  </div>
                  <p>{valuesQuestionarePt[2].value}</p>
                  <div className={css.valuesBar}>
                    <div
                      className={css.valuesScore}
                      style={{
                        marginRight: `${
                          ((5 - userData.listValues[2]) * 100) / 5
                        }%`,
                      }}
                    ></div>
                  </div>
                </div>
              )}
              <div>
                {userData.listValues && (
                  <>
                    <p>{valuesQuestionarePt[3].value}</p>
                    <div className={css.valuesBar}>
                      <div
                        className={css.valuesScore}
                        style={{
                          marginRight: `${
                            ((5 - userData.listValues[3]) * 100) / 5
                          }%`,
                        }}
                      ></div>
                    </div>
                    <p>{valuesQuestionarePt[4].value}</p>
                    <div className={css.valuesBar}>
                      <div
                        className={css.valuesScore}
                        style={{
                          marginRight: `${
                            ((5 - userData.listValues[4]) * 100) / 5
                          }%`,
                        }}
                      ></div>
                    </div>
                    <p>{valuesQuestionarePt[5].value}</p>
                    <div className={css.valuesBar}>
                      <div
                        className={css.valuesScore}
                        style={{
                          marginRight: `${
                            ((5 - userData.listValues[5]) * 100) / 5
                          }%`,
                        }}
                      ></div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={css.profile6}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/personality")}
            >
              edit_note
            </i>
            <h4>Personalidade</h4>
            <p>
              Emoção: {Math.round((userData.personalityTraits[0] * 100) / 35)}%
            </p>
            <div className={css.traitBarTemp}>
              <div
                className={css.traitScoreTemp}
                style={{
                  marginRight: `${
                    ((35 - userData.personalityTraits[0]) * 100) / 35
                  }%`,
                }}
              ></div>
            </div>
            <p>
              Energia: {Math.round((userData.personalityTraits[1] * 100) / 35)}%
            </p>
            <div className={css.traitBarTemp}>
              <div
                className={css.traitScoreTemp}
                style={{
                  marginRight: `${
                    ((35 - userData.personalityTraits[1]) * 100) / 35
                  }%`,
                }}
              ></div>
            </div>
            <p>
              Social: {Math.round((userData.personalityTraits[2] * 100) / 35)}%
            </p>
            <div className={css.traitBarTemp}>
              <div
                className={css.traitScoreTemp}
                style={{
                  marginRight: `${
                    ((35 - userData.personalityTraits[2]) * 100) / 35
                  }%`,
                }}
              ></div>
            </div>
            <div className={css.traitName}>
              <p>
                Regras:{" "}
                {handleCharacter(
                  "regras",
                  Math.round((userData.personalityTraits[3] * 100) / 40)
                )}
              </p>
            </div>
            <div className={css.traitBar}>
              <div
                className={css.traitScore}
                style={{
                  marginLeft: `${(userData.personalityTraits[3] * 100) / 40}%`,
                }}
              ></div>
            </div>
            <div className={css.traitCap}>
              <p>Disciplinado</p>
              <p>Espontâneo</p>
            </div>
            <div className={css.traitName}>
              <p>
                Abstração:{" "}
                {handleCharacter(
                  "abstração",
                  Math.round((userData.personalityTraits[4] * 100) / 50)
                )}
              </p>
            </div>
            <div className={css.traitBar}>
              <div
                className={css.traitScore}
                style={{
                  marginLeft: `${(userData.personalityTraits[4] * 100) / 50}%`,
                }}
              ></div>
            </div>
            <div className={css.traitCap}>
              <p>Imaginativo</p>
              <p>Realista</p>
            </div>
            <div className={css.traitName}>
              <p>
                Flexibilidade:{" "}
                {handleCharacter(
                  "flexibilidade",
                  Math.round((userData.personalityTraits[4] * 100) / 50)
                )}
              </p>
            </div>
            <div className={css.traitBar}>
              <div
                className={css.traitScore}
                style={{
                  marginLeft: `${(userData.personalityTraits[4] * 100) / 50}%`,
                }}
              ></div>
            </div>
            <div className={css.traitCap}>
              <p>Assertivo</p>
              <p>Turbulento</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={css.container}>
          <div className={css.title}>
            <button className={css.cvBtnIn} onClick={() => navigate("/login")}>
              Criar um perfil !
            </button>
          </div>
        </div>
      )}
    </>
  );
};
