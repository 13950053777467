import React, { useContext, useState, useEffect } from "react";

import css from "./Today.module.css";
import { Head } from "../../Utils/Head";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";

export const Start = () => {
  const { userData } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      userData.personalityTraits && setIndex(1);
      userData.listValues && setIndex(2);
    }
  }, [userData]);

  if (!userData) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={css.container}>
        <Head title="User" description={"User - Mairini"} />
        <section>
          <h4>Siga os passos abaixo para iniciar</h4>
          {index === 0 ? (
            <div
              className={`${css.cardStart} ${css.cardStartActive}`}
              onClick={() => navigate("/personality")}
            >
              <h4>Entendendo sobre a sua personalidade</h4>
            </div>
          ) : (
            <div className={css.cardStart}>
              <h4>Entendendo sobre a sua personalidade</h4>
            </div>
          )}

          {index === 1 ? (
            <div
              className={`${css.cardStart} ${css.cardStartActive}`}
              onClick={() => navigate("/values")}
            >
              <h4>Encontrando as direções da sua vida</h4>
            </div>
          ) : (
            <div className={css.cardStart}>
              <h4>Encontrando as direções da sua vida</h4>
            </div>
          )}
          <div className={css.cardStart}>
            <h4>Transformando valores em ações</h4>
          </div>
        </section>
      </div>
    );
  }
};
