import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./Personality.module.css";
import { CBMPI } from "../../Utils/CBMPI";
import { UserContext } from "../../Context/UserContext";
import { updateUserFirebase } from "../../Utils/firebaseUser";
import { Button } from "../Forms/Button";

export const Personality = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const CBMPIarr = new Array(42);
  for (let i = 0; i < CBMPIarr.length; i++) {
    CBMPIarr[i] = new Array(5).fill(false);
  }
  const [CBMPICheck, setCBMPICheck] = useState(CBMPIarr);
  const [completeCBMPI, setCompleteCBMPI] = useState(0);
  const navigate = useNavigate();

  function handleCheckCBMPI(row, col) {
    let sum = completeCBMPI + 1;
    setCompleteCBMPI(sum);
    let array = [...CBMPICheck];
    if (array[row].includes(true)) {
      if (array[row][col]) {
        array[row][col] = false;
        setCBMPICheck(array);
      } else {
        let position = array[row].indexOf(true);
        array[row][position] = false;
        array[row][col] = !array[row][col];
        setCBMPICheck(array);
      }
    } else {
      array[row][col] = !array[row][col];
      setCBMPICheck(array);
    }
  }

  function submmit() {
    let CBMPIReduced = CBMPICheck.map((row) => row.indexOf(true));
    CBMPIReduced = CBMPIReduced.map((item) => item + 1);
    const CBMPIAnswers = new Array(42).fill("");
    for (let index = 0; index < CBMPIReduced.length; index++) {
      CBMPIAnswers[index] = CBMPI[CBMPIReduced[index]]
        ? CBMPI[CBMPIReduced[index]]
        : "";
    }

    function reverseItem(item) {
      if (item == 1) {
        return Number(5);
      } else if (item == 2) {
        return Number(4);
      } else if (item == 3) {
        return Number(3);
      } else if (item == 4) {
        return Number(2);
      } else if (item == 5) {
        return Number(1);
      }
    }

    const emotionality =
      CBMPIReduced[0] +
      CBMPIReduced[6] +
      reverseItem(CBMPIReduced[12]) +
      CBMPIReduced[18] +
      reverseItem(CBMPIReduced[24]) +
      CBMPIReduced[30] +
      reverseItem(CBMPIReduced[36]);
    const activity =
      CBMPIReduced[1] +
      reverseItem(CBMPIReduced[7]) +
      CBMPIReduced[13] +
      reverseItem(CBMPIReduced[19]) +
      CBMPIReduced[25] +
      reverseItem(CBMPIReduced[31]) +
      CBMPIReduced[37];
    const sociability =
      CBMPIReduced[2] +
      reverseItem(CBMPIReduced[8]) +
      CBMPIReduced[14] +
      reverseItem(CBMPIReduced[20]) +
      CBMPIReduced[26] +
      reverseItem(CBMPIReduced[32]) +
      CBMPIReduced[38];
    const ruleFollowing =
      CBMPIReduced[3] +
      reverseItem(CBMPIReduced[9]) +
      CBMPIReduced[15] +
      reverseItem(CBMPIReduced[21]) +
      CBMPIReduced[27] +
      reverseItem(CBMPIReduced[33]) +
      CBMPIReduced[39];
    const cognitiveFlexibility =
      CBMPIReduced[4] +
      CBMPIReduced[10] +
      reverseItem(CBMPIReduced[16]) +
      CBMPIReduced[22] +
      reverseItem(CBMPIReduced[28]) +
      CBMPIReduced[34] +
      reverseItem(CBMPIReduced[40]);
    const psychologicalFlexibility =
      CBMPIReduced[5] +
      reverseItem(CBMPIReduced[11]) +
      CBMPIReduced[17] +
      reverseItem(CBMPIReduced[23]) +
      CBMPIReduced[29] +
      reverseItem(CBMPIReduced[35]) +
      CBMPIReduced[41];

    const personalityTraits = [
      emotionality,
      activity,
      sociability,
      ruleFollowing,
      cognitiveFlexibility,
      psychologicalFlexibility,
    ];

    userData.personalityTraits = personalityTraits;

    updateUserFirebase(userData);
    setUserData(userData);
    navigate("/");
  }

  if (userData) {
    if (index === 0) {
      return (
        <div className={css.container}>
          <h3>Avaliação da Personalidade</h3>
          <p>
            A seguir estão listadas várias afirmações sobre como você pode se
            sentir ou se comportar. Marque para cada afirmação a resposta que
            melhor descreve você em uma escala de 1 a 5 pontos, em que:
          </p>
          <ul>
            <li>1 = discordo totalmente.</li>
            <li>2 = discordo um pouco.</li>
            <li>3 = nem concordo, nem discordo.</li>
            <li>4 = concordo um pouco.</li>
            <li>5 = concordo totalmente.</li>
          </ul>
          <p>
            Leia cada item com atenção e marque a resposta que melhor
            corresponde com o seu nível de concordância e discordância. Não há
            respostas certas ou erradas. Descreva-se de forma honesta e exponha
            suas opiniões o mais fielmente possível.
          </p>
          <p>Clique abaixo para iniciar</p>
          <div className={css.footer}>
            <button className="saveButton" onClick={() => setIndex(1)}>
              Iniciar
            </button>
          </div>
        </div>
      );
    } else if (index === 1) {
      return (
        <div className={css.container}>
          <form>
            {CBMPI.map((question, row) => (
              <React.Fragment key={row}>
                <p>
                  {row + 1}. {question}
                </p>

                <label className={css.confirmation}>
                  discordo totalmente
                  <input
                    type="checkbox"
                    checked={CBMPICheck[row][0]}
                    onChange={() => {
                      handleCheckCBMPI(row, 0);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  discordo
                  <input
                    type="checkbox"
                    checked={CBMPICheck[row][1]}
                    onChange={() => {
                      handleCheckCBMPI(row, 1);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  neutro
                  <input
                    type="checkbox"
                    checked={CBMPICheck[row][2]}
                    onChange={() => {
                      handleCheckCBMPI(row, 2);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  concordo
                  <input
                    type="checkbox"
                    checked={CBMPICheck[row][3]}
                    onChange={() => {
                      handleCheckCBMPI(row, 3);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  concordo totalmente
                  <input
                    type="checkbox"
                    checked={CBMPICheck[row][4]}
                    onChange={() => {
                      handleCheckCBMPI(row, 4);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
              </React.Fragment>
            ))}
          </form>
          <div className={css.footer}>
            <Button
              onClick={() => {
                submmit();
              }}
            >
              Enviar
            </Button>
          </div>
        </div>
      );
    }
  } else {
    return <div>Loading...</div>;
  }
};
